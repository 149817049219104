@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* $$$$$$$$$$$$$$$$$ LIGHT $$$$$$$$$$$$$$$$$ */

  /*[data-theme='light'] { */
  :root {
    --color-background-100: #f0f5ff;
    --color-background-80: #ffffff;
    --color-background-60: #28251c;

    --color-caret-100: #007aff;

    /* ######### PRIMARY ######### */
    --color-primary-100: linear-gradient(
      90deg,
      #ab6e19 0%,
      #b47b23 1%,
      #cda243 6%,
      #e2c15b 12.19%,
      #f0d76d 17.14%,
      #f9e477 23.29%,
      #fce97b 30.63%,
      #f9e475 39.4%,
      #efd665 50.49%,
      #e0c04b 63.59%,
      #e8c853 74%,
      #fce97b 80.88%,
      #f8e475 87%,
      #eed465 90%,
      #ddbb4b 94%,
      #c59826 98%,
      #ba8916 100%
    );
    --color-primary-80: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.8) 0%,
      rgba(180, 123, 35, 0.8) 1%,
      rgba(205, 162, 67, 0.8) 6%,
      rgba(226, 193, 91, 0.8) 12.19%,
      rgba(240, 215, 109, 0.8) 17.14%,
      rgba(249, 228, 119, 0.8) 23.29%,
      rgba(252, 233, 123, 0.8) 30.63%,
      rgba(249, 228, 117, 0.8) 39.4%,
      rgba(239, 214, 101, 0.8) 50.49%,
      rgba(224, 192, 75, 0.8) 63.59%,
      rgba(232, 200, 83, 0.8) 74%,
      rgba(252, 233, 123, 0.8) 80.88%,
      rgba(248, 228, 117, 0.8) 87%,
      rgba(238, 212, 101, 0.8) 90%,
      rgba(221, 187, 75, 0.8) 94%,
      rgba(197, 152, 38, 0.8) 98%,
      rgba(186, 137, 22, 0.8) 100%
    );
    --color-primary-60: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.6) 0%,
      rgba(180, 123, 35, 0.6) 1%,
      rgba(205, 162, 67, 0.6) 6%,
      rgba(226, 193, 91, 0.6) 12.19%,
      rgba(240, 215, 109, 0.6) 17.14%,
      rgba(249, 228, 119, 0.6) 23.29%,
      rgba(252, 233, 123, 0.6) 30.63%,
      rgba(249, 228, 117, 0.6) 39.4%,
      rgba(239, 214, 101, 0.6) 50.49%,
      rgba(224, 192, 75, 0.6) 63.59%,
      rgba(232, 200, 83, 0.6) 74%,
      rgba(252, 233, 123, 0.6) 80.88%,
      rgba(248, 228, 117, 0.6) 87%,
      rgba(238, 212, 101, 0.6) 90%,
      rgba(221, 187, 75, 0.6) 94%,
      rgba(197, 152, 38, 0.6) 98%,
      rgba(186, 137, 22, 0.6) 100%
    );
    --color-primary-40: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.4) 0%,
      rgba(180, 123, 35, 0.4) 1%,
      rgba(205, 162, 67, 0.4) 6%,
      rgba(226, 193, 91, 0.4) 12.19%,
      rgba(240, 215, 109, 0.4) 17.14%,
      rgba(249, 228, 119, 0.4) 23.29%,
      rgba(252, 233, 123, 0.4) 30.63%,
      rgba(249, 228, 117, 0.4) 39.4%,
      rgba(239, 214, 101, 0.4) 50.49%,
      rgba(224, 192, 75, 0.4) 63.59%,
      rgba(232, 200, 83, 0.4) 74%,
      rgba(252, 233, 123, 0.4) 80.88%,
      rgba(248, 228, 117, 0.4) 87%,
      rgba(238, 212, 101, 0.4) 90%,
      rgba(221, 187, 75, 0.4) 94%,
      rgba(197, 152, 38, 0.4) 98%,
      rgba(186, 137, 22, 0.4) 100%
    );
    --color-primary-20: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.2) 0%,
      rgba(180, 123, 35, 0.2) 1%,
      rgba(205, 162, 67, 0.2) 6%,
      rgba(226, 193, 91, 0.2) 12.19%,
      rgba(240, 215, 109, 0.2) 17.14%,
      rgba(249, 228, 119, 0.2) 23.29%,
      rgba(252, 233, 123, 0.2) 30.63%,
      rgba(249, 228, 117, 0.2) 39.4%,
      rgba(239, 214, 101, 0.2) 50.49%,
      rgba(224, 192, 75, 0.2) 63.59%,
      rgba(232, 200, 83, 0.2) 74%,
      rgba(252, 233, 123, 0.2) 80.88%,
      rgba(248, 228, 117, 0.2) 87%,
      rgba(238, 212, 101, 0.2) 90%,
      rgba(221, 187, 75, 0.2) 94%,
      rgba(197, 152, 38, 0.2) 98%,
      rgba(186, 137, 22, 0.2) 100%
    );
    --color-primary-10: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.1) 0%,
      rgba(180, 123, 35, 0.1) 1%,
      rgba(205, 162, 67, 0.1) 6%,
      rgba(226, 193, 91, 0.1) 12.19%,
      rgba(240, 215, 109, 0.1) 17.14%,
      rgba(249, 228, 119, 0.1) 23.29%,
      rgba(252, 233, 123, 0.1) 30.63%,
      rgba(249, 228, 117, 0.1) 39.4%,
      rgba(239, 214, 101, 0.1) 50.49%,
      rgba(224, 192, 75, 0.1) 63.59%,
      rgba(232, 200, 83, 0.1) 74%,
      rgba(252, 233, 123, 0.1) 80.88%,
      rgba(248, 228, 117, 0.1) 87%,
      rgba(238, 212, 101, 0.1) 90%,
      rgba(221, 187, 75, 0.1) 94%,
      rgba(197, 152, 38, 0.1) 98%,
      rgba(186, 137, 22, 0.1) 100%
    );
    --color-primary-05: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.05) 0%,
      rgba(180, 123, 35, 0.05) 1%,
      rgba(205, 162, 67, 0.05) 6%,
      rgba(226, 193, 91, 0.05) 12.19%,
      rgba(240, 215, 109, 0.05) 17.14%,
      rgba(249, 228, 119, 0.05) 23.29%,
      rgba(252, 233, 123, 0.05) 30.63%,
      rgba(249, 228, 117, 0.05) 39.4%,
      rgba(239, 214, 101, 0.05) 50.49%,
      rgba(224, 192, 75, 0.05) 63.59%,
      rgba(232, 200, 83, 0.05) 74%,
      rgba(252, 233, 123, 0.05) 80.88%,
      rgba(248, 228, 117, 0.05) 87%,
      rgba(238, 212, 101, 0.05) 90%,
      rgba(221, 187, 75, 0.05) 94%,
      rgba(197, 152, 38, 0.05) 98%,
      rgba(186, 137, 22, 0.05) 100%
    );

    /* ######### INK ######### */
    --color-ink-100: #1b1b29;
    --color-ink-80: #565a76;
    --color-ink-60: #797f9d;
    --color-ink-40: #9fa7c4;
    --color-ink-20: #c2cbe1;
    --color-ink-10: #e0e7f5;
    --color-ink-05: #f0f5ff;

    /* ######### Black Opacity ######### */
    --color-black-opacity-100: #1b1b29;
    --color-black-opacity-80: rgba(27, 27, 41, 0.8);
    --color-black-opacity-60: rgba(27, 27, 41, 0.6);
    --color-black-opacity-40: rgba(27, 27, 41, 0.4);
    --color-black-opacity-20: rgba(27, 27, 41, 0.2);
    --color-black-opacity-10: rgba(27, 27, 41, 0.1);
    --color-black-opacity-05: rgba(27, 27, 41, 0.05);

    /* ######### Red ######### */
    --color-red-100: #ff0e00;
    --color-red-80: #ff3e33;
    --color-red-60: #ff6e66;
    --color-red-40: #ff9e99;
    --color-red-20: #ffcfcc;
    --color-red-10: #ffe7e6;
    --color-red-05: #fff3f2;
    --color-red-circle: #c2180f;

    /* ######### Green ######### */
    --color-green-100: #34c759;
    --color-green-80: #5dd27a;
    --color-green-60: #85dd9b;
    --color-green-40: #aee9bd;
    --color-green-20: #d6f4de;
    --color-green-10: #ebfaef;
    --color-green-05: #f5fcf6;
    --color-green-circle: #07a42e;

    /* ######### Yellow ######### */
    --color-yellow-100: #ffcc00;
    --color-yellow-80: #ffd633;
    --color-yellow-60: #ffe066;
    --color-yellow-40: #ffeb99;
    --color-yellow-20: #fff5cc;
    --color-yellow-10: #fffae6;
    --color-yellow-05: #fffcf2;

    /* ######### Orange ######### */
    --color-orange-100: #ff9500;
    --color-orange-80: #ffaa33;
    --color-orange-60: #ffbf66;
    --color-orange-40: #ffd599;
    --color-orange-20: #ffeacc;
    --color-orange-10: #fff5e6;
    --color-orange-05: #fff9f2;

    /* ######### Blue ######### */
    --color-blue-100: #007aff;
    --color-blue-80: #3395ff;
    --color-blue-60: #66afff;
    --color-blue-40: #99caff;
    --color-blue-20: #cce4ff;
    --color-blue-10: #e6f2ff;
    --color-blue-05: #f2f8ff;

    /* ######### Teal ######### */
    --color-teal-100: #5ac8fa;
    --color-teal-80: #7bd3fb;
    --color-teal-60: #9cdefc;
    --color-teal-40: #bde9fd;
    --color-teal-20: #def4fe;
    --color-teal-10: #effaff;
    --color-teal-05: #f6fcfe;

    /* ######### Indigo ######### */
    --color-indigo-100: #5856d6;
    --color-indigo-80: #7978de;
    --color-indigo-60: #9b9ae6;
    --color-indigo-40: #bcbbef;
    --color-indigo-20: #deddf7;
    --color-indigo-10: #efeffb;
    --color-indigo-05: #f6f6fd;

    /* ######### Purple ######### */
    --color-purple-100: #af52de;
    --color-purple-80: #bf75e5;
    --color-purple-60: #cf97eb;
    --color-purple-40: #dfbaf2;
    --color-purple-20: #efdcf8;
    --color-purple-10: #f7eefc;
    --color-purple-05: #fbf6fd;

    /* ######### STAR ######### */
    --color-star-100: radial-gradient(
      50% 50% at 50% 50%,
      #ffffff 0%,
      #fffdf7 1%,
      #fff7e2 2%,
      #ffedbe 3%,
      #ffdf8e 4%,
      #ffcd4f 5%,
      #ffb700 7%,
      #ffa400 12%,
      #ff9100 16%,
      #d07600 23%,
      #a05b00 30%,
      #754200 38%,
      #512e00 46%,
      #331d00 55%,
      #1d1000 64%,
      #0c0700 74%,
      #030200 85%,
      #000000 100%
    );

    --bg-dropdown: #282624;
  }

  /* $$$$$$$$$$$$$$$$$ DARK $$$$$$$$$$$$$$$$$ */

  /* [data-theme='dark'] { */
  .dark {
    --color-background-100: #0f0e0c;
    --color-background-80: #1d1b18;
    --color-background-60: #28251c;

    --color-caret-100: #ffcc00;

    /* ######### PRIMARY ######### */
    --color-primary-100: linear-gradient(
      90deg,
      #ab6e19 0%,
      #b47b23 1%,
      #cda243 6%,
      #e2c15b 12.19%,
      #f0d76d 17.14%,
      #f9e477 23.29%,
      #fce97b 30.63%,
      #f9e475 39.4%,
      #efd665 50.49%,
      #e0c04b 63.59%,
      #e8c853 74%,
      #fce97b 80.88%,
      #f8e475 87%,
      #eed465 90%,
      #ddbb4b 94%,
      #c59826 98%,
      #ba8916 100%
    );
    --color-primary-80: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.8) 0%,
      rgba(180, 123, 35, 0.8) 1%,
      rgba(205, 162, 67, 0.8) 6%,
      rgba(226, 193, 91, 0.8) 12.19%,
      rgba(240, 215, 109, 0.8) 17.14%,
      rgba(249, 228, 119, 0.8) 23.29%,
      rgba(252, 233, 123, 0.8) 30.63%,
      rgba(249, 228, 117, 0.8) 39.4%,
      rgba(239, 214, 101, 0.8) 50.49%,
      rgba(224, 192, 75, 0.8) 63.59%,
      rgba(232, 200, 83, 0.8) 74%,
      rgba(252, 233, 123, 0.8) 80.88%,
      rgba(248, 228, 117, 0.8) 87%,
      rgba(238, 212, 101, 0.8) 90%,
      rgba(221, 187, 75, 0.8) 94%,
      rgba(197, 152, 38, 0.8) 98%,
      rgba(186, 137, 22, 0.8) 100%
    );
    --color-primary-60: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.6) 0%,
      rgba(180, 123, 35, 0.6) 1%,
      rgba(205, 162, 67, 0.6) 6%,
      rgba(226, 193, 91, 0.6) 12.19%,
      rgba(240, 215, 109, 0.6) 17.14%,
      rgba(249, 228, 119, 0.6) 23.29%,
      rgba(252, 233, 123, 0.6) 30.63%,
      rgba(249, 228, 117, 0.6) 39.4%,
      rgba(239, 214, 101, 0.6) 50.49%,
      rgba(224, 192, 75, 0.6) 63.59%,
      rgba(232, 200, 83, 0.6) 74%,
      rgba(252, 233, 123, 0.6) 80.88%,
      rgba(248, 228, 117, 0.6) 87%,
      rgba(238, 212, 101, 0.6) 90%,
      rgba(221, 187, 75, 0.6) 94%,
      rgba(197, 152, 38, 0.6) 98%,
      rgba(186, 137, 22, 0.6) 100%
    );
    --color-primary-40: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.4) 0%,
      rgba(180, 123, 35, 0.4) 1%,
      rgba(205, 162, 67, 0.4) 6%,
      rgba(226, 193, 91, 0.4) 12.19%,
      rgba(240, 215, 109, 0.4) 17.14%,
      rgba(249, 228, 119, 0.4) 23.29%,
      rgba(252, 233, 123, 0.4) 30.63%,
      rgba(249, 228, 117, 0.4) 39.4%,
      rgba(239, 214, 101, 0.4) 50.49%,
      rgba(224, 192, 75, 0.4) 63.59%,
      rgba(232, 200, 83, 0.4) 74%,
      rgba(252, 233, 123, 0.4) 80.88%,
      rgba(248, 228, 117, 0.4) 87%,
      rgba(238, 212, 101, 0.4) 90%,
      rgba(221, 187, 75, 0.4) 94%,
      rgba(197, 152, 38, 0.4) 98%,
      rgba(186, 137, 22, 0.4) 100%
    );
    --color-primary-20: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.2) 0%,
      rgba(180, 123, 35, 0.2) 1%,
      rgba(205, 162, 67, 0.2) 6%,
      rgba(226, 193, 91, 0.2) 12.19%,
      rgba(240, 215, 109, 0.2) 17.14%,
      rgba(249, 228, 119, 0.2) 23.29%,
      rgba(252, 233, 123, 0.2) 30.63%,
      rgba(249, 228, 117, 0.2) 39.4%,
      rgba(239, 214, 101, 0.2) 50.49%,
      rgba(224, 192, 75, 0.2) 63.59%,
      rgba(232, 200, 83, 0.2) 74%,
      rgba(252, 233, 123, 0.2) 80.88%,
      rgba(248, 228, 117, 0.2) 87%,
      rgba(238, 212, 101, 0.2) 90%,
      rgba(221, 187, 75, 0.2) 94%,
      rgba(197, 152, 38, 0.2) 98%,
      rgba(186, 137, 22, 0.2) 100%
    );
    --color-primary-10: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.1) 0%,
      rgba(180, 123, 35, 0.1) 1%,
      rgba(205, 162, 67, 0.1) 6%,
      rgba(226, 193, 91, 0.1) 12.19%,
      rgba(240, 215, 109, 0.1) 17.14%,
      rgba(249, 228, 119, 0.1) 23.29%,
      rgba(252, 233, 123, 0.1) 30.63%,
      rgba(249, 228, 117, 0.1) 39.4%,
      rgba(239, 214, 101, 0.1) 50.49%,
      rgba(224, 192, 75, 0.1) 63.59%,
      rgba(232, 200, 83, 0.1) 74%,
      rgba(252, 233, 123, 0.1) 80.88%,
      rgba(248, 228, 117, 0.1) 87%,
      rgba(238, 212, 101, 0.1) 90%,
      rgba(221, 187, 75, 0.1) 94%,
      rgba(197, 152, 38, 0.1) 98%,
      rgba(186, 137, 22, 0.1) 100%
    );
    --color-primary-05: linear-gradient(
      90deg,
      rgba(171, 110, 25, 0.05) 0%,
      rgba(180, 123, 35, 0.05) 1%,
      rgba(205, 162, 67, 0.05) 6%,
      rgba(226, 193, 91, 0.05) 12.19%,
      rgba(240, 215, 109, 0.05) 17.14%,
      rgba(249, 228, 119, 0.05) 23.29%,
      rgba(252, 233, 123, 0.05) 30.63%,
      rgba(249, 228, 117, 0.05) 39.4%,
      rgba(239, 214, 101, 0.05) 50.49%,
      rgba(224, 192, 75, 0.05) 63.59%,
      rgba(232, 200, 83, 0.05) 74%,
      rgba(252, 233, 123, 0.05) 80.88%,
      rgba(248, 228, 117, 0.05) 87%,
      rgba(238, 212, 101, 0.05) 90%,
      rgba(221, 187, 75, 0.05) 94%,
      rgba(197, 152, 38, 0.05) 98%,
      rgba(186, 137, 22, 0.05) 100%
    );

    /* ######### INK ######### */
    --color-ink-100: #ffffff;
    --color-ink-80: rgba(255, 255, 255, 0.8);
    --color-ink-60: rgba(255, 255, 255, 0.6);
    --color-ink-40: rgba(255, 255, 255, 0.4);
    --color-ink-20: rgba(255, 255, 255, 0.2);
    --color-ink-10: rgba(255, 255, 255, 0.1);
    --color-ink-05: rgba(255, 255, 255, 0.05);

    /* ######### Black Opacity ######### */
    --color-black-opacity-100: #ffffff;
    --color-black-opacity-80: rgba(255, 255, 255, 0.8);
    --color-black-opacity-60: rgba(255, 255, 255, 0.6);
    --color-black-opacity-40: rgba(255, 255, 255, 0.4);
    --color-black-opacity-20: rgba(255, 255, 255, 0.2);
    --color-black-opacity-10: rgba(255, 255, 255, 0.1);
    --color-black-opacity-05: rgba(255, 255, 255, 0.05);

    /* ######### Red ######### */
    --color-red-100: #ff3e33;
    --color-red-80: rgba(255, 62, 51, 0.8);
    --color-red-60: rgba(255, 62, 51, 0.6);
    --color-red-40: rgba(255, 62, 51, 0.4);
    --color-red-20: rgba(255, 62, 51, 0.2);
    --color-red-10: rgba(255, 62, 51, 0.1);
    --color-red-05: rgba(255, 62, 51, 0.05);

    /* ######### Green ######### */
    --color-green-100: #34c759;
    --color-green-80: rgba(52, 199, 89, 0.8);
    --color-green-60: rgba(52, 199, 89, 0.6);
    --color-green-40: rgba(52, 199, 89, 0.4);
    --color-green-20: rgba(52, 199, 89, 0.2);
    --color-green-10: rgba(52, 199, 89, 0.1);
    --color-green-05: rgba(52, 199, 89, 0.05);

    /* ######### Yellow ######### */
    --color-yellow-100: #ffcc00;
    --color-yellow-80: rgba(255, 204, 0, 0.8);
    --color-yellow-60: rgba(255, 204, 0, 0.6);
    --color-yellow-40: rgba(255, 204, 0, 0.4);
    --color-yellow-20: rgba(255, 204, 0, 0.2);
    --color-yellow-10: rgba(255, 204, 0, 0.1);
    --color-yellow-05: rgba(255, 204, 0, 0.05);

    /* ######### Orange ######### */
    --color-orange-100: #ff9500;
    --color-orange-80: rgba(255, 149, 0, 0.8);
    --color-orange-60: rgba(255, 149, 0, 0.6);
    --color-orange-40: rgba(255, 149, 0, 0.4);
    --color-orange-20: rgba(255, 149, 0, 0.2);
    --color-orange-10: rgba(255, 149, 0, 0.1);
    --color-orange-05: rgba(255, 149, 0, 0.05);

    /* ######### Blue ######### */
    --color-blue-100: #007aff;
    --color-blue-80: rgba(0, 122, 255, 0.8);
    --color-blue-60: rgba(0, 122, 255, 0.6);
    --color-blue-40: rgba(0, 122, 255, 0.4);
    --color-blue-20: rgba(0, 122, 255, 0.2);
    --color-blue-10: rgba(0, 122, 255, 0.1);
    --color-blue-05: rgba(0, 122, 255, 0.05);

    /* ######### Teal ######### */
    --color-teal-100: #5ac8fa;
    --color-teal-80: rgba(90, 200, 250, 0.8);
    --color-teal-60: rgba(90, 200, 250, 0.6);
    --color-teal-40: rgba(90, 200, 250, 0.4);
    --color-teal-20: rgba(90, 200, 250, 0.2);
    --color-teal-10: rgba(90, 200, 250, 0.1);
    --color-teal-05: rgba(90, 200, 250, 0.05);

    /* ######### Indigo ######### */
    --color-indigo-100: #5856d6;
    --color-indigo-80: rgba(88, 86, 214, 0.8);
    --color-indigo-60: rgba(88, 86, 214, 0.6);
    --color-indigo-40: rgba(88, 86, 214, 0.4);
    --color-indigo-20: rgba(88, 86, 214, 0.2);
    --color-indigo-10: rgba(88, 86, 214, 0.1);
    --color-indigo-05: rgba(88, 86, 214, 0.05);

    /* ######### Purple ######### */
    --color-purple-100: #af52de;
    --color-purple-80: rgba(175, 82, 222, 0.8);
    --color-purple-60: rgba(175, 82, 222, 0.6);
    --color-purple-40: rgba(175, 82, 222, 0.4);
    --color-purple-20: rgba(175, 82, 222, 0.2);
    --color-purple-10: rgba(175, 82, 222, 0.1);
    --color-purple-05: rgba(175, 82, 222, 0.05);

    /* ######### STAR ######### */
    --color-star-100: radial-gradient(
      50% 50% at 50% 50%,
      #ffffff 0%,
      #fffdf7 1%,
      #fff7e2 2%,
      #ffedbe 3%,
      #ffdf8e 4%,
      #ffcd4f 5%,
      #ffb700 7%,
      #ffa400 12%,
      #ff9100 16%,
      #d07600 23%,
      #a05b00 30%,
      #754200 38%,
      #512e00 46%,
      #331d00 55%,
      #1d1000 64%,
      #0c0700 74%,
      #030200 85%,
      #000000 100%
    );

    --bg-dropdown: #282624;
  }
}

html {
  font-size: 16px;
}

@media screen and (max-device-width: 1800px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-device-width: 1536px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-device-width: 1280px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-device-width: 1024px) {
  html {
    font-size: 16px;
  }
}

/* @media screen and (max-device-width: 1024px) {
  html {
    font-size: 10px;
  }
} */

/* @media screen and (max-device-width: 768px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-device-width: 640px) {
  html {
    font-size: 10px;
  }
} */

html,
body {
  overflow-x: hidden;
  max-width: 100%;
  /* height: 100%; */
}

body {
  margin: 0;
  font-family: SFProText, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background-100);
  color: var(--color-ink-100);
}

.overflow-html {
  overflow-x: initial;
  max-width: auto;
  position: fixed;
}
.overflow-body {
  overflow: hidden;
  position: fixed;
}

.navbar {
  /* position: sticky; */
  position: fixed;
  width: 100vw;
  top: 0;
  margin-left: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  transition: all 0.3s ease-in-out;
}

/* .navbar.fixed {
  position: fixed;
  width: 100vw;
  margin-left: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
} */

.upsert-form-max-height {
  height: calc(var(--vh, 1vh) * 100);
}

@media screen and (min-device-width: 767px) {
  .navbar {
    margin-left: -3rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .upsert-form-max-height {
    height: unset;
  }
}

@media screen and (min-device-width: 1023px) {
  .navbar {
    margin-left: -5rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-ink-10);
  border-radius: 24px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-ink-40);
  border-radius: 24px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-ink-80);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--color-ink-100) !important;
}

/* BORDER */

.border-primary {
  position: relative;
}

.border-primary:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 0;
  border-radius: 12px;
  padding: 2px;
  background: var(--color-blue-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.dark .border-primary:before {
  background: var(--color-primary-100);
}

.border-primary-z-0 {
  position: relative;
}

.border-primary-z-0:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 2px;
  background: var(--color-primary-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.underline-primary {
  position: relative;
}

.underline-primary:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 1px;
  background: var(--color-primary-100);
}

/* INPUT */

.input-icon-seperator {
  position: relative;
}

.input-icon-seperator:before {
  content: '';
  position: absolute;
  right: -0.625rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background: var(--color-ink-10);
}

.caret-input {
  caret-color: var(--color-caret-100);
}

.border-input-red {
  position: relative;
}

.border-input-red:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 0;
  border-radius: 12px;
  padding: 2px;
  background: var(--color-red-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.border-input-ink {
  position: relative;
}

.border-input-ink:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 0;
  border-radius: 12px;
  padding: 2px;
  background: var(--color-ink-10);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.border-input-ink:hover:before {
  background: var(--color-primary-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.bg-primary-05-hover:hover {
  background: var(--color-primary-05);
}

/* PAGINATION */
li.border-input-ink:hover a,
li.pagination-active a {
  background: var(--color-primary-100);
  background-clip: text;
  color: transparent;
}
a.page-link {
  display: inline-block;
  min-width: 0.75rem;
  text-align: center;
}

/* FOCUS */

.border-primary-focus {
  position: relative;
}

.border-primary-input input:focus {
  outline: none;
}

.border-primary-focus:before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: 1rem;
  padding: 1px;
  background: var(--color-primary-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* BORDER BOTTOM */

.border-bottom-primary {
  position: relative;
  transition: all 0.2s linear;
}

.border-bottom-primary:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: auto;
  right: 0;
  height: 2px;
  width: 100%;
  background: var(--color-primary-100);
  transition: all 0.2s linear;
}

/* BUTTON BORDER */

.border-primary-button {
  position: relative;
}

.border-primary-button:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  padding: 2px;
  background: var(--color-primary-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.border-circle-primary {
  position: relative;
  margin: 0.375rem;
}

.border-circle-primary:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  padding: 1.75px;
  margin: -0.375rem;
  background: var(--color-primary-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.border-circle-ink {
  position: relative;
  margin: 0.375rem;
}

.border-circle-ink:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  padding: 1.75px;
  margin: -0.375rem;
  background: var(--color-ink-10);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.radio-container-hover:hover {
  background: var(--color-primary-05);
}

.border-radio-primary {
  position: relative;
}

.border-radio-primary:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  padding: 1.75px;
  background: var(--color-primary-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.border-radio-ink {
  position: relative;
}

.border-radio-ink:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  padding: 1.75px;
  background: var(--color-ink-10);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* RADIO */

[type='radio'] {
  display: none;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
}

[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--color-ink-40);
  border-radius: 100%;
}

[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 9999px;
  padding: 2px;
  width: 1.25rem;
  height: 1.25rem;
  background: var(--color-primary-100);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  position: absolute;
  top: calc(50% + 4px);
  left: 4px;
  transform: translateY(calc(-50% - 4px));
  width: 0.675rem;
  height: 0.675rem;
  background: var(--color-primary-100);
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) translateY(calc(-50% - 4px));
  transform: scale(0) translateY(calc(-50% - 4px));
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) translateY(calc(-50% - 4px));
  transform: scale(1) translateY(calc(-50% - 4px));
}

.bg-gold {
  background: linear-gradient(
    90deg,
    #ab6e19 0%,
    #b47b23 1%,
    #cda243 6%,
    #e2c15b 12.19%,
    #f0d76d 17.14%,
    #f9e477 23.29%,
    #fce97b 30.63%,
    #f9e475 39.4%,
    #efd665 50.49%,
    #e0c04b 63.59%,
    #e8c853 74%,
    #fce97b 80.88%,
    #f8e475 87%,
    #eed465 90%,
    #ddbb4b 94%,
    #c59826 98%,
    #ba8916 100%
  );
}

.text-gold {
  background: linear-gradient(
    90deg,
    #ab6e19 0%,
    #b47b23 1%,
    #cda243 6%,
    #e2c15b 12.19%,
    #f0d76d 17.14%,
    #f9e477 23.29%,
    #fce97b 30.63%,
    #f9e475 39.4%,
    #efd665 50.49%,
    #e0c04b 63.59%,
    #e8c853 74%,
    #fce97b 80.88%,
    #f8e475 87%,
    #eed465 90%,
    #ddbb4b 94%,
    #c59826 98%,
    #ba8916 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-star {
  background-blend-mode: screen;
  mix-blend-mode: screen;
  opacity: 0.8;
}

.small-caps {
  font-variant: small-caps;
}

/* CHECK BOX */
input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  /* background-color only for content */
  background-clip: content-box;
  background-image: url('../assets/images/Copy_Checkbox.svg');
  background-size: 1.5rem 1.5rem;
  /* margin-left: 15px; */
  /* margin-right: 15px; */
}

input[type='checkbox']:checked {
  outline: none !important;
  background-image: url('../assets/images/Copy_Checkbox_V.svg');
}

input[type='checkbox'][disabled] {
  outline: none !important;
  background-image: url('../assets/images/Copy_Checkbox_Disabled.svg');
  cursor: not-allowed;
}

.login-bg {
  /* background-image: url('../assets/images/Login_out_Background.jpg'); */
  background-image: url('../assets/images/Login_out_Tet_Background.png');

  min-height: 100vh;
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  /* position: relative; */
  /* background-size: 100% 100%; */
}

.background-animation {
  background-size: 400% 400%;
  animation: gradient 10s ease infinite alternate;
}

.react-switch-bg {
  height: 24px !important;
}

.react-switch-bg div {
  height: 24px !important;
}

.react-switch:has(input[type='checkbox']:checked) .react-switch-bg {
  background: var(--color-primary-100) !important;
}

.react-switch-handle {
  width: 18px !important;
  height: 18px !important;
  top: 3px !important;
  left: 3px !important;
  background: var(--color-ink-100) !important;
}

.react-switch:has(input[type='checkbox']) .react-switch-bg {
  background: var(--color-ink-10) !important;
}

/* Border for select component */
.border-select-input {
  position: relative;
}

.border-select-input:after {
  content: '';
  position: absolute;
  top: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%);
  background: var(--color-ink-10);
  width: calc(100% - 2rem);
  height: 1px;
}

.border-select-input-two:not(:last-child) div {
  border-bottom: 1px solid var(--color-ink-10);
}

/* SELECT INPUT HOVER */
.select-input-hover:hover {
  background-image: var(--color-primary-05);
}

.select-input-hover:hover p {
  color: var(--color-ink-100);
}

.shadow-popup {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.cubic-bezier {
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

tr td:first-child,
tr th:first-child {
  width: 1%;
  white-space: nowrap;
}

/* .is-sticky:nth-last-of-type(2) {
  position: sticky;
  right: 42px;
  width: 100px;
  border-left: 2px solid var(--color-ink-20);
}

.is-sticky:nth-last-of-type(1) {
  width: 42px;
  position: sticky;
  right: 0;
}

.is-sticky.is-row {
  background: var(--color-background-80);
} */

/* th,
tr,
td {
  padding: 0;
  margin: 0;
} */

/* Table Input */

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
